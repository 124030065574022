import {checkVersion} from "version-rocket";

checkVersion(
  // config
  {
    // 5分钟检测一次版本
    // pollingTime: 5 * 60 * 1000,
    pollingTime: 5 * 60 * 1000,
    // immediate: true,
    // @ts-expect-error ignore
    localPackageVersion: window?.Joe?.BLOG_VERSION,
    originVersionFileUrl: `${location.origin}/version.json`
  },
  // options
  {
    title: '个人博客',
    description: "检测到新版本",
    buttonText: "立即更新",
    primaryColor: "#409EFF",
  }
);

document.addEventListener('DOMContentLoaded', async () => {
  {
    updateWakatimeEl()

    // 5 分钟更新一次 wakatime 数据
    setInterval(updateWakatimeEl, 5 * 60 * 1000)

    // 获取我的 wakatime 数据
    function getMyWakatime() {
      return new Promise((resolve, _reject) => {
        $.ajax({
          // @ts-expect-error ignore
          url: window.Joe.BASE_API,
          type: 'POST',
          dataType: 'json',
          data: {
            routeType: 'my_wakatime',
          },
          success(res) {
            resolve(res)
          }
        });
      })
    }

    async function updateWakatimeEl() {
      const res: any = await getMyWakatime()
      if (res.code === 1) {
        const total = res.data.total
        const range = res.data.range
        const langs = res.data.languages?.slice(0, 8)
        const wakatime = $('.joe_aside__item.wakatime')
        const wakatimeContainer = $('.joe_aside__item.wakatime .joe_aside__item-contain')
        const html = `
          <div class="flex items-center justify-between leading-[1.6]">
            <span style="color: var(--routine);" class="text-[12px]">日期</span>
            <span style="color: var(--main);" class="text-[15px]">${range}</span>
          </div>
          <div class="flex items-center justify-between leading-[1.6]">
            <span style="color: var(--routine);" class="text-[12px]">总计</span>
            <span style="color: var(--main);" class="text-[15px] dark:!text-green-500/50">${total}</span>
          </div>
  
          <div class="my-[10px]" style="border-top: 1px solid var(--classC);"></div>
  
          <div>
            ${langs?.map((lang: any, index: number) => {
              let color;
              switch (index) {
                case 0:
                  color = '#FFD700';
                  break;
                case 1:
                  color = '#C0C0C0';
                  break;
                case 2:
                  color = '#CD7F32';
                  break;
                default:
                  color = 'var(--routine)';
              }
              return `
                <div class="flex items-center justify-between leading-[1.6]">
                  <div style="color: ${color};" class="text-[12px]">${lang.name}</div>
                  <div style="color: var(--main);" class="text-[15px]">${lang.text}</div>
                </div>
              `
            }).join('\n')}
          </div>
        `
        
        wakatime.fadeIn(300, function() {
          $(this).show()
          wakatimeContainer.fadeOut(400, function() {
            $(this).html(html).slideDown(500)
          })
        })
      }
    }
  }
})
